import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const TrustpilotWidget = ({
  template,
  businessUnitId,
  theme = "dark",
  link,
  height = template.height,
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [ template, businessUnitId ])

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id={template.id}
      data-businessunit-id={businessUnitId}
      data-style-height={height}
      data-style-width="100%"
      data-theme={theme}
    >
      <a href={link} target="_blank" rel="noopener noreferrer">
        Trustpilot
      </a>
    </div>
  )
}

export default TrustpilotWidget

TrustpilotWidget.propTypes = {
  template: PropTypes.object.isRequired,
  businessUnitId: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
}
